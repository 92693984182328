import {getTimeZoneOffsets, getActualTime, printActualTime} from "./time";
import moment from 'moment';
import {helpers} from '../global/setUserParamsLegacy';
import {addConfirmationDetails, hideMessageBox, submitFormW1, validateSection,showPromptToCallMessageBox,initDateTimeField,savePromptToCallCampaign} from "./formFunctions";
import Cookie from "js-cookie";
import {progressBar} from "../routes/abtesting";

export function w1_step3() {

  var $theForm = $( 'form.schedule-consultation-form' );
  var formID = $theForm.find( 'input[name="gform_submit"]' ).val();
  var $wrapper = $( '.schedule-consultation-form_wrapper' );
  var $messageBox = $( '#webform-component-messages' );
  global.ccUserInfo.numberOfPrompts=0;

  $('.field-agree-to-terms').click(function(){
    $(".date-time-errors.agree-to-terms-error").hide();
  });
  /**
   * custom stuff to alter w2 things to make it work as w1
   */
  // show the terms/checkbox on step3 load and remove asterisk
  $('.field-agree-to-terms').show();


  // move the time list to the right place
  $("#choose-date--timelist").detach().appendTo(".month-calendar-left");
  $(".change-date-time").addClass('d-none');
  $(".choose-date-timelist").append("<div class='timelist-error-container'></div>");
  $(".choose-date-timelist").append("<input type='button' class='timelist-next-button disabled' id='timelist-next-button' value='next'>");
  $(".choose-day-timelist .select-time").slideUp();
  $(".choose-day-timelist").addClass("oneMonthCal");
  $(".field-agree-to-terms").addClass("oneMonth");
  $(".field-agree-to-terms .gfield_label").remove();

  $(".month-calendar-right").slideUp();

  // change the bootstrap classes to have the calendar a little bigger than the right column
  $('.make-appointment > .row > .col-md-6').each(function (i,v){
    $(this).removeClass('col-md-6');

    if( i === 0 && $('#webFormCalendar2').length == 0){
      $(this).addClass('col-md-8');
    }
    else if($('#webFormCalendar2').length == 0){
      $(this).addClass('col-md-4');
    } else{
      $(this).addClass('col-md-6');
    }
  });

  // move the agreement, and submit btn into the right column
  $wrapper.find('.field-agree-to-terms').detach().appendTo('.right-wrapper');

  $.when(global.saveLeadInfoLock).then(function(success){
    initDateTimeField();
  });


  $wrapper.on( 'click', '#temp_submit_button', function() {
    submitFormW1( formID );
  } );


  $messageBox.on( 'click', '.dismiss, .bg-overlay', function() {
    hideMessageBox();
  } );


  //dataLayer for clicking on any calendar time
  $wrapper.on( 'click', 'td.CalActive', function() {
    var dateInfo = $( '.selected' ).data();
    var monthInfo = dateInfo.month != undefined ? dateInfo.month : dateInfo.nextMonth;
    global.dataLayer.push({'event':'clickSelectDate',
    'currentlySelectedDate':monthInfo + "/"  + dateInfo.day + "/" + dateInfo.year});
    if(!($($(this)).hasClass('promptToCall'))){
      global.dataLayer.push({'currentlySelectedYear':dateInfo.year});
      global.dataLayer.push({'currentlySelectedMonth':monthInfo});
      global.dataLayer.push({'currentlySelectedDay':dateInfo.day});
      global.dataLayer.push({'currentlySelectedDateNOPTC':monthInfo + "/"  + dateInfo.day + "/" + dateInfo.year});
    } else{
      global.ccUserInfo.numberOfPrompts++;
      global.dataLayer.push({'promptToCallDateSelected':monthInfo + "/"  + dateInfo.day + "/" + dateInfo.year});
      global.dataLayer.push({'centerPTC': global.ccUserInfo.centerID});
      global.dataLayer.push({'timestamp': Date.now()});
      global.dataLayer.push({'LeadSegmen20PTC': global.ccUserInfo.leadSegment20});
      var daysBetweenTimestamp = new Date(monthInfo + "/"  + dateInfo.day + "/" + dateInfo.year) - new Date();
      var daysBetween = Math.ceil(daysBetweenTimestamp / (1000 * 3600 * 24));
      global.dataLayer.push({'Days between Appt date and form visit date': daysBetween});
      global.dataLayer.push({'event': "PTCDateClicked"});
    }
  });


  /**
   * this is when they click the calender?
   */

  //When you select a date on the Calendar, display the Times
  $wrapper.on( 'click', '#webFormCalendar td.CalActive', function(e) {

    if($(this).hasClass('promptToCall')){
      // showPromptToCallMessageBox();
      $(".timelist-error-container").html("");
      $(".timelist-error-container").html("We're sorry, the date you've selected is unavailable for online scheduling. Please select another day or call us for assistance.");
      $(".timelist-error-container").show();

      $( '#choose-date--timelist ul > li' ).removeClass( 'selected' );
      $('.choose-day-timelist ul').slideUp();
      $(".choose-day-timelist .select-time").slideUp();

      $(".promptToCall").css('background','#ebebeb');
      global.dataLayer.push( { 'event': 'Schedule Disabled' } );
      global.dataLayer.push( { 'event': 'Schedule Disabled On Click' } );
      savePromptToCallCampaign();
      return;
    }else{
      $(".timelist-error-container").hide();
    }



    var $webFormCalendar = $( this ).closest( '#webFormCalendar' );

    // Test: Monthly Calendar 5/3/16
    if ( ! $webFormCalendar.hasClass( 'cc-web-form-calendar-month' ) ) {
      $( '#confirmation-details' ).slideUp();
    }

    var $selectObject = $( '#choose-date--timelist ul' );

    if( $webFormCalendar.find( '.selected' ).length > 0 ){

      var dateInfo = $( '#webFormCalendar .selected' ).data();

      $selectObject.empty();
      $(".timelist-next-button").addClass('disabled');

      //Display the correct times.
      var times = {};
      $.each( ccUserInfo.availableAppointments.seminars, function( index, time ) {
        if ( time.date === dateInfo.year + '-' + dateInfo.month + '-' + dateInfo.day ) {

          var offset = getTimeZoneOffsets( ccUserInfo.availableAppointments.timezone, ccUserInfo.availableAppointments.timezone );

          var myDate = getActualTime( dateInfo.year, dateInfo.month, dateInfo.day, time.start, offset );

          var myDateText = printActualTime( myDate );

          var filledClass = '';
          if ( time.blocks.length === 0 ) {
            filledClass = ' class="slot-filled" ';
          }

          $selectObject.append(
            $( '<li' + filledClass + '></li>' )
              .attr( {
                "data-timeslot": time.blocks.join( '.' ),
                "data-timestring": moment(myDate).format( 'dddd, MMMM DD, YYYY<br/> h:mma' ),
                "data-calendarstart": moment(myDate).format( 'YYYY-MM-DD HH:mm:ss' ),
                "data-calendarend": moment(myDate).add( 1, 'h' ).format( 'YYYY-MM-DD HH:mm:ss' ),
              } )
              .text( myDateText ) );
              // .text( myDateText + ' ' + ccUserInfo.availableAppointments.timezone ) );
        }
      } );

      $(".timelist-next-button").slideDown();

      $(".timelist-next-button").click(function(){
        if(!$(this).hasClass('disabled')){
          $(".timelist-error-container").hide();
          var $calendarWrapper = $( '#webFormCalendar .choose-date-month .month-wrapper' );
          var $calendarTitleDiv = $( '#webFormCalendar .choose-date-month > div:first-child' );
          //remove the select date time and header
          $(".select-date-time").addClass('d-none');
          $(".date-time-paragraph").addClass('d-none');
          $(".date-time-paragraph").addClass('d-none');
          $(".select-date").addClass('d-none');
          $(".select-time").addClass('d-none');
          $(".change-date-time").removeClass('d-none');
          //collapse timelist when a time is selected (put this functionality in next button instead)
          $("#choose-date--timelist ul").slideUp();
          $('.choose-date-timelist' ).addClass( 'collapsed' );
          $('.choose-date-timelist' ).find( 'h3' ).slideDown();
          $( '.make-appointment' ).removeClass( 'disabled-button' );
          // also collapse calendar
          $calendarTitleDiv.closest( '.choose-date-month' ).addClass( 'collapsed' );
          $calendarTitleDiv.find( '.calendar-title-nav' ).slideUp();
          $calendarTitleDiv.find( '.date-selection' ).slideDown();
          $calendarWrapper.slideUp();
          // collapse this button as well
          $(".timelist-next-button").slideUp();
          $(".month-calendar-right").slideDown();
          progressBar(4);
          $('html, body').animate({
            scrollTop: $(".page-datetime-confirm").offset().top,
          }, 200);
        }else{
          $(".timelist-error-container").html("");
          if(!$("td.CalActive.selected").length){
            $(".timelist-error-container").append("<div class='date-select-error'>Please select date to continue</div>");
          }
          $(".timelist-error-container").append("<div  class='time-select-error'>Please select time to continue</div>");
          $(".timelist-error-container").show();
        }
      });

      // Test: Monthly Calendar 5/3/16
      if ( $webFormCalendar.hasClass( 'cc-web-form-calendar-month' ) ) {

        var $calendarWrapper = $webFormCalendar.find( '.choose-date-month .month-wrapper' );
        var $calendarTitleDiv = $webFormCalendar.find( '.choose-date-month > div:first-child' );
        var appointmentDate = 'Date goes here';
        var monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        var dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        var dateSelection = new Date();
        dateSelection.setFullYear( dateInfo.year );
        dateSelection.setMonth( ( parseInt( dateInfo.month, 10 ) -1 ), 1 );
        dateSelection.setDate( dateInfo.day );

        var dateSelectionText = dayNames[dateSelection.getDay()] + ', ' + monthNames[dateSelection.getMonth()] + ' ' + dateSelection.getDate() + ', ' + dateSelection.getFullYear();

        // Set Global to Date Selection (no time yet)
        global.ccUserInfo.appointmentString = dateSelectionText;

        // $calendarTitleDiv.closest( '.choose-date-month' ).addClass( 'collapsed' );
        // $calendarTitleDiv.find( '.calendar-title-nav' ).slideUp();
        $( '.js-day-selection' ).html( dateSelectionText );

        // Populate hidden date field
        // $webFormCalendar.find( '.js-choose-day' ).focus().val( dateSelectionText ).attr( 'value', dateSelectionText ).blur();
        $webFormCalendar.find( '.js-choose-day' ).val( dateSelectionText ).attr( 'value', dateSelectionText ).blur();

        $( '#choose-date--timelist .choose-date-timelist' ).removeClass( 'collapsed' );
        $( '#choose-date--timelist .choose-date-timelist h3.date-selection' ).slideUp();
        $selectObject.slideDown();
        $(".choose-day-timelist .select-time").slideDown();
        e.stopPropagation();

        // $calendarTitleDiv.find( '.date-selection' ).slideDown();
        // $calendarWrapper.slideUp();
        if (! $( '.make-appointment' ).hasClass( 'disabled-button' ) ) {
          $( '.make-appointment' ).addClass( 'disabled-button' );

        }
        $selectObject.closest( '#choose-date--timelist' ).slideDown( '300' );
        $selectObject.parent().slideDown( '300' );

        // Animate Scrolldown to Time Selection section
        if( $( window ).width() <= 774 ) {
          setTimeout( function() {
            helpers.scrollPageTo( '#choose-date--timelist .select-time', 120 );
          }, 300 );
        }

        $( '#confirmation-details .appt-details .date' ).html( global.ccUserInfo.appointmentString + '<br /><span>Select a time</span>' );
        //End Test 5/3/16

      } else {

        $selectObject.parent().slideDown();

        // Animate Scrolldown to Time Date Selection
        // if( $( window ).width() <= 774 ) {
        //   helpers.scrollPageTo( '#choose-date--timelist', 20 );
        // }

      }

    } else {
      $selectObject.parent().slideUp();
    }
    ccUserInfo.appointment = null;
  } );


  /**
   * click change (date), sends them back to the calendar
   */
  // Test: Monthly Calendar 5/3/16
  $wrapper.on( 'click', '#webFormCalendar .js-change-day', function( e ) 	{
    global.dataLayer.push({'event':'clickChangeDate'});
    var $selectObject = $( '#choose-date--timelist ul' );
    var $calendarWrapper = $( '#webFormCalendar .choose-date-month .month-wrapper' );
    var $calendarTitleDiv = $( '#webFormCalendar .choose-date-month > div:first-child' );

    $calendarTitleDiv.closest( '.choose-date-month' ).removeClass( 'collapsed' );
    $calendarTitleDiv.find( '.calendar-title-nav' ).slideDown();
    $calendarTitleDiv.find( '.date-selection' ).slideUp();
    $calendarWrapper.slideDown();
    if (! $( '.make-appointment' ).hasClass( 'disabled-button' ) ) {
      $( '.make-appointment' ).addClass( 'disabled-button' );
    }
    $( '#choose-date--timelist .choose-date-timelist' ).removeClass( 'collapsed' );
    $( '#choose-date--timelist .choose-date-timelist h3.date-selection' ).slideUp();
    $selectObject.slideDown();
    $(".timelist-next-button").slideDown();
    //$( '#webFormCalendar .js-change-time' ).click();
    $(".month-calendar-right").slideUp();

    progressBar(3);
    e.stopPropagation();

  } );


  /**
   * click change (for time), sends them back to the calendar
   */
  $wrapper.on( 'click', '#webFormCalendar .js-change-time', function( e ) {
    global.dataLayer.push({'event': 'clickChangeTime'});
    var $selectObject = $( '#choose-date--timelist ul' );
    var $calendarWrapper = $( '#webFormCalendar .choose-date-month .month-wrapper' );
    var $calendarTitleDiv = $( '#webFormCalendar .choose-date-month > div:first-child' );

    $calendarTitleDiv.closest( '.choose-date-month' ).removeClass( 'collapsed' );
    $calendarTitleDiv.find( '.calendar-title-nav' ).slideDown();
    $calendarTitleDiv.find( '.date-selection' ).slideUp();
    $calendarWrapper.slideDown();
    if (! $( '.make-appointment' ).hasClass( 'disabled-button' ) ) {
      $( '.make-appointment' ).addClass( 'disabled-button' );
    }
    $( '#choose-date--timelist .choose-date-timelist' ).removeClass( 'collapsed' );
    $( '#choose-date--timelist .choose-date-timelist h3.date-selection' ).slideUp();
    $selectObject.slideDown();
    $(".timelist-next-button").slideDown();
    $(".month-calendar-right").slideUp();
    progressBar(3);
    e.stopPropagation();

  } );
  //End Test 5/3/16


  /**
   * click change (for time), sends them back to the calendar for new calendar 10/20/2023
   */
  $wrapper.on( 'click', '#webFormCalendarChangeDateTime .js-change-time', function( e ) {
    global.dataLayer.push({'event': 'clickChangeTime'});
    var $selectObject = $( '#choose-date--timelist ul' );
    var $calendarWrapper = $( '#webFormCalendar .choose-date-month .month-wrapper' );
    var $calendarTitleDiv = $( '#webFormCalendar .choose-date-month > div:first-child' );
    $(".select-date-time").removeClass('d-none');
    $(".date-time-paragraph").removeClass('d-none');
    $(".date-time-paragraph").removeClass('d-none')
    $(".select-date").removeClass('d-none');
    $(".select-time").removeClass('d-none');
    $(".change-date-time").addClass('d-none');
    $calendarTitleDiv.closest( '.choose-date-month' ).removeClass( 'collapsed' );
    $calendarTitleDiv.find( '.calendar-title-nav' ).slideDown();
    $calendarTitleDiv.find( '.date-selection' ).slideUp();
    $calendarWrapper.slideDown();
    if (! $( '.make-appointment' ).hasClass( 'disabled-button' ) ) {
      $( '.make-appointment' ).addClass( 'disabled-button' );
    }
    $( '#choose-date--timelist .choose-date-timelist' ).removeClass( 'collapsed' );
    $( '#choose-date--timelist .choose-date-timelist h3.date-selection' ).slideUp();
    $selectObject.slideDown();
    $(".timelist-next-button").slideDown();
    $(".month-calendar-right").slideUp();
    $('html, body').animate({
      scrollTop: $(".select-date-time").offset().top,
    }, 1000);
    progressBar(3);
    e.stopPropagation();
  } );
  //End Test 5/3/16


  /**
   * click any of the time slots
   */
  $wrapper.on( 'click', '#choose-date--timelist ul > li', function( e ) {
    var $this = $( this );
    if( ! $this.data( 'timeslot' ) ){
      return;
    }
    $(".timelist-error-container").hide();

    $( '#choose-date--timelist ul > li' ).removeClass( 'selected' );
    $this.addClass( 'selected' );
    $(".timelist-next-button").removeClass('disabled');
    var $webFormCalendar = $( '#webFormCalendar' );
    var $calendarWrapper = $webFormCalendar.find( '.choose-date-month .month-wrapper' );
    var $calendarTitleDiv = $webFormCalendar.find( '.choose-date-month > div:first-child' );
    var timeData = $("#choose-date--timelist ul > li.selected").data();
    var appointmentStartTimeDateObject = new Date(timeData.calendarstart);
    var currentTimeString = appointmentStartTimeDateObject.getHours()+":"+appointmentStartTimeDateObject.getMinutes();
    var amOrpm = timeData.timestring.includes("am") ? "am" : "pm";
    currentTimeString = currentTimeString + " " + amOrpm;

    //add to data layer for selecting time
    global.dataLayer.push({'event':'clickSelectTime',
    'appointmentTime':currentTimeString});

    // Add the user to a "Date/Time Selected" Campaign (only the first time they select a timeslot) - 11/28/2016
    if( ccUserInfo.appointment === '' || ccUserInfo.appointment === null ) {
      var scheduledNotConfirmed = {};
      scheduledNotConfirmed.GUID = ccUserInfo.guid;
      scheduledNotConfirmed.isContact = ( ccUserInfo.contactID !== '' && ccUserInfo.contactID !== null ) ? true : false;
      scheduledNotConfirmed.TransId = 'onlinescheddateandtime';
      scheduledNotConfirmed.action = 'cc_salesforce_submit_campaign_member';
      scheduledNotConfirmed.securityToken = global.securityToken;
      scheduledNotConfirmed.testing = window.ccUserInfo.testing;

      jQuery.ajax( {
        url: ajaxurl,
        data: scheduledNotConfirmed,
        cache: false,
        dataType: 'json',
        type: 'POST',
        success: function( result, success ) {
          //console.log( 'Success: ', result );
        },
        error: function( result, error ) {
          //console.log( 'Error: ', result );
        },
      } );
    }

    global.ccUserInfo.appointment = $this.data( 'timeslot' );
    // global.ccUserInfo.asppointmentString = $this.data( 'calendarstart' );
    global.ccUserInfo.appointmentString = $this.data( 'timestring' );
    global.ccUserInfo.calendarTimeString = $this.data( 'calendarstart' );
    global.ccUserInfo.calendarTimeEndString = $this.data( 'calendarend' );

    $( '#confirmation-details .appt-details .date' ).html( global.ccUserInfo.appointmentString );

    addConfirmationDetails( formID );

    // Test: Monthly Calendar 5/3/16
    if ( $( '#webFormCalendar' ).hasClass( 'cc-web-form-calendar-month' ) ) {
      var timeString = $this.html();
      //collapse timelist when a time is selected (put this functionality in next button instead)
      // $this.closest( 'ul' ).slideUp();
      // $this.closest( '.choose-date-timelist' ).addClass( 'collapsed' );
      $this.closest( '.choose-date-timelist' ).find( 'h3 span.js-time-selection' ).html( timeString );
      // $this.closest( '.choose-date-timelist' ).find( 'h3' ).slideDown();
      // $( '.make-appointment' ).removeClass( 'disabled-button' );
      // also collapse calendar
      // $calendarTitleDiv.closest( '.choose-date-month' ).addClass( 'collapsed' );
      // $calendarTitleDiv.find( '.calendar-title-nav' ).slideUp();
      // $calendarTitleDiv.find( '.date-selection' ).slideDown();
      // $calendarWrapper.slideUp();

      // Populate hidden date field
      // $( '#webFormCalendar' ).find( '.js-choose-time' ).focus().val( timeString ).attr( 'value', timeString ).blur();
      $( '#webFormCalendar' ).find( '.js-choose-time' ).val( timeString ).attr( 'value', timeString ).blur();
      // Animate Scrolldown to Confirmation section
      if( $( window ).width() <= 774 ) {
        // Wait until animation is done
        // setTimeout( function() {
        //   helpers.scrollPageTo( '.month-calendar-right', 160 );
        // }, 300 );
      }

      e.stopPropagation();
    } else {

      // Animate Scrolldown to Confirmation section
      if( $( window ).width() <= 774 ) {
        // helpers.scrollPageTo( '#confirmation-details', 20 );
      }
    }
    //End Test 5/3/16
  } );


  //Initiate Call Me Now
  $messageBox.find(".call-me-now-box .buttons > .green").click(function(){


    //Validate the section and collect the errors
    var errors = validateSection( "call-me-now" );
    var animationDuration = 300;

    if(errors.length === 0){

      $messageBox.find(".waiting-icon-container").slideDown(animationDuration);
      $messageBox.find(".call-me-now-form").slideUp();

      var leadData = {};

      leadData.action = "cc_salesforce_initiate_call_me_now";

      //If they're just repeating step one.
      if(ccUserInfo.guid !== ''){
        leadData.GUID = ccUserInfo.guid;
      }

      var $callmenowFirstName = $( '#call-me-now-firstName' );
      var $callmenowLastName = $( '#call-me-now-lastName' );
      var $callmenowPhone = $( '#call-me-now-phone' );

      leadData.FirstName = $callmenowFirstName.val();
      leadData.LastName = $callmenowLastName.val();
      leadData.Phone = $callmenowPhone.val();

      if( ccUserInfo.address ){
        leadData.Address = ccUserInfo.address;
      }
      if( ccUserInfo.city ){
        leadData.City = ccUserInfo.city;
      }
      if( ccUserInfo.state ){
        leadData.State = ccUserInfo.state;
      }
      if( ccUserInfo.zipcode ){
        leadData.Zip = ccUserInfo.zipcode;
      }

      if( ccUserInfo.email !== ''){
        leadData.Email = ccUserInfo.email;
      }

      if( ccUserInfo.availableCenters && ccUserInfo.centerID){
        var currentCenterData = ccUserInfo.availableCenters[ ccUserInfo.centerID ];
        leadData.CenterId = currentCenterData.id;
      }
      leadData.testing = window.ccUserInfo.testing;

      jQuery.ajax({
        type: "POST",
        url: ajaxurl,
        data: leadData,
        success: function( msg ){
          $(".call-me-now-box").html("<p>Thank you! You've successfully submitted your request. We look forward to speaking with you shortly.</p><div class='buttons'><div class='dismiss'>OK</div></div>");
          $(".call-me-now-box").find(".buttons > .dismiss").click(function(){
            hideMessageBox();
          });

        },
        error: function(msg){
          $messageBox.find(".waiting-icon-container").slideUp(animationDuration);
          //console.log("Ajax Error initiating Call-Me-Now:");
          $messageBox.find(".call-me-now-form").prepend("<p style='color: red;'>Sorry, something went wrong with your request. Try again later.</p>").slideDown();

          global.dataLayer.push({ 'error_data': msg });
          global.dataLayer.push({ 'error_message': 'Ajax Error initiating Call-Me-Now' });
          global.dataLayer.push({ 'event': 'ajax-error' });
        },
      });
    }else{
      alert("Please complete all required fields to continue.");
    }
  });
}

export function initiateStep3MonthCal() {
  $( '#patient-birth-date').addClass('w1-right-input');
 }

