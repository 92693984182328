import Cookie from "js-cookie";

function sendFacebookConversionApiEvent(eventName,eventId){
  var eventData = getAdditionalUserInfoForFacebookConversionCall();
  eventData.eventName = eventName;
  eventData.eventId = eventId;
  eventData.action = "cc_send_facebook_conversion_api_event_call";
  jQuery.ajax({
    url: ajaxurl,
    data: eventData,
    cache: false,
    dataType: "json",
    type: "POST",
    success: function( result ) {
      console.log( 'Success: '+result );
    },
    error: function( result ) {
      console.log( 'Error: '+result );
    },
  });
}

// Only fire the pixel and CAPI event for Social group active population (category 5), 11/03
export function sendFacebookEvent(eventName){
  if(global.OptanonActiveGroups != undefined && global.OptanonActiveGroups.indexOf("5") >= 0){
    var facebookEventId = createFacebookEventId();
    if(typeof global.fbq !== 'undefined'){
      console.log('doing something'+eventName+"id:"+facebookEventId);
      // global.fbq('track',eventName,{'fillerInfo':"info"},{'eventID':facebookEventId});
    }else{
      // global.fbq('track',eventName,{'fillerInfo':"info"}, {'eventID':facebookEventId});
      console.log('not working');
    }
    // sendFacebookConversionApiEvent(eventName,facebookEventId);
  }
}

function createFacebookEventId(){
    //Code for creating uuid from:
    //https://www.w3resource.com/javascript-exercises/javascript-math-exercise-23.php
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
}

function getAdditionalUserInfoForFacebookConversionCall(){
  var additionalFacebookInfo = {};
  additionalFacebookInfo.fpb = Cookie.get('_fbp');
  additionalFacebookInfo.fpc = Cookie.get('_fbc');
  additionalFacebookInfo.userIp = $("meta[name=userIp]").attr("content");
  additionalFacebookInfo.originUrl = window.location.origin;
  additionalFacebookInfo.userAgent = navigator.userAgent;
  if(global.ccUserInfo!= undefined && global.ccUserInfo.firstName != undefined){
    additionalFacebookInfo.FirstName = global.ccUserInfo.firstName;
  }
  if(global.ccUserInfo!= undefined && global.ccUserInfo.lastName != undefined){
    additionalFacebookInfo.LastName = global.ccUserInfo.lastName;
  }
  if(global.ccUserInfo!= undefined && global.ccUserInfo.email != undefined){
    additionalFacebookInfo.Email = global.ccUserInfo.email;
  }

  return additionalFacebookInfo;
}
